import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { SnackbarProvider, closeSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ruRU as coreRuRU } from '@mui/material/locale';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ruRU as dataGridRuRU } from '@mui/x-data-grid/locales';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ruRU as pickersRuRU } from '@mui/x-date-pickers/locales';

import { ServiceModeProvider } from '@features/alkozamki_service_mode/hooks/ServiceModeContext';
import { ErrorBoundary } from '@layout/error_boundary';
import { UserProvider } from '@pages/users/UserContext';
import { routers } from '@shared/config/routers';
import { UserStatusProvider } from '@shared/ui/refetch/UserStatusContext';
import { StatusFilterProvider } from '@shared/ui/search_multiple_select/StatusFilterContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CountProvider } from '@widgets/nav_bar/api/CountContext';
import { UserContextProvider } from '@widgets/users_info/UserContext';
import { AlkoContextProvider } from '@widgets/vehicles_info/lib/AlkoContext';

import './index.scss';
import * as serviceWorker from './serviceWorker';

const queryClient = new QueryClient();

// Создаём тему с русской локализацией
const theme = createTheme(coreRuRU, pickersRuRU, dataGridRuRU);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider
              localeText={pickersRuRU.components.MuiLocalizationProvider.defaultProps.localeText}>
              <UserStatusProvider>
                <ServiceModeProvider>
                  <UserContextProvider>
                    <CountProvider>
                      <AlkoContextProvider>
                        <StatusFilterProvider>
                          <SnackbarProvider
                            action={(snackbarId) => (
                              <CloseIcon
                                className="CloseIcon"
                                onClick={() => closeSnackbar(snackbarId)}
                              />
                            )}
                            maxSnack={3}
                            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                            autoHideDuration={null}>
                            <RouterProvider router={routers} />
                          </SnackbarProvider>
                        </StatusFilterProvider>
                      </AlkoContextProvider>
                    </CountProvider>
                  </UserContextProvider>
                </ServiceModeProvider>
              </UserStatusProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </QueryClientProvider>
    </UserProvider>
  </ErrorBoundary>,
);

// Регистрируем сервис-воркер
serviceWorker.register();
