/* eslint-disable @typescript-eslint/no-explicit-any */ 
import type { JSX, ReactNode } from 'react'; 
 
import { Chip, type ChipOwnProps, Tooltip } from '@mui/material'; 
 
import { ChipCopyTextIcon } from '@shared/ui/copy_text_icon/ChipCopyTextIcon'; 
 
import style from '../ui/Info.module.scss'; 
 
export interface GetTypeOfRowIconValueProps extends ChipOwnProps { 
  copyble?: boolean; 
  tooltip?: boolean; 
  element?: string | number | ReactNode | JSX.Element; 
  copyText?: string | number; 
  customStyled?: boolean; 
} 
 
export const getTypeOfRowIconValue = ({ 
  copyble = false, 
  tooltip = false, 
  element = null, 
  copyText, 
  customStyled = false, 
  ...rest 
}: GetTypeOfRowIconValueProps) => { 
  if (element) return element; 
  const label = { ...rest }?.label || ''; 
  const count = 
    typeof label === 'string' || typeof label === 'number' ? label?.toString().length : 33; 
 
  const commonChipStyles = { 
    fontSize: '16px', 
    // padding: '8px', // Добавляем отступы 
    // boxSizing: 'border-box' as any, 
  }; 
 
  const chip = copyble ? ( 
    <ChipCopyTextIcon copyText={copyText} {...rest} style={style.labelText} /> 
  ) : ( 
    <Chip {...rest} className={style.labelText} /> 
  ); 
 
  const shouldApplyCustomChip = [ 
    'Точка возникновения', 
    'Код ответа блока интеграции', 
    'Описание ошибки', 
  ]; 
 
  const tooltipTitle = copyText ?? ({ ...rest }.label || ''); 
 
  const castomChip = 
    customStyled && shouldApplyCustomChip ? ( 
      <ChipCopyTextIcon 
        copyText={copyText} 
        variant="outlined" 
        sx={{ 
          backgroundColor: 'transparent', 
          boxSizing: 'border-box', 
          border: 'none', 
          borderRadius: 0, 
          padding: '2px', 
          fontSize: '16px', 
        }} 
        {...rest} 
      /> 
    ) : ( 
      <Chip {...rest} style={commonChipStyles} /> 
    ); 
 
  return tooltip || count >= 33 ? ( 
    <Tooltip title={tooltipTitle}> 
      <div className={style.wrapperText}>{castomChip}</div> 
    </Tooltip> 
  ) : ( 
    <div className={style.wrapperText}>{chip}</div> 
  ); 
};
